import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Link } from "gatsby"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()


const SandsPage = (props) => {
  let language = "en"

  const post = props.data.allUniquePagesJson.nodes[0];

  return (
    <SharedStateProvider>
      <Layout pageTitle="sands-page" language={language}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
    <div className="joshua-tree-content">
            <div className="columns">
              <div className="column">
                <div className="columns top-section text-section white-back">
                  <div className="column is-5"></div>
                  <div className="column">
                    <div>
                      <h1>{post.heading}</h1>
                      <p className="large">{post.topBlurb}</p>
                    </div>
                  </div>
                  <div className="column is-5"></div>
                </div>
              </div>
            </div>


            <div className="columns" style={{paddingBottom: "60px"}}>
              <div className="column">
                <div className="columns">
                  <div className="column is-5"></div>
                  <div className="column is-9">
                  <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.imageId}
                  width="auto"
                  responsive
                >
                </ImageMeta>
                  </div>
                  <div className="column is-1"></div>
                  <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.list)
                  )}>
            </div>
                  <div className="column is-3"></div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="columns">
                  <div className="column is-5"></div>
                  <div className="column is-9" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.body)
                  )}>
            </div>
                  <div className="column"></div>
                  <div className="column is-3"></div>
                </div>
              </div>
            </div>

            <section className="body-sections section" style={{padding: "60px 0"}}>
              <div className="columns has-text-centered">
                <div className="column is-5"></div>
                <div className="column">
                  <h3 style={{ marginBottom: "1.5rem" }}>{post.getStarted.heading}</h3>
                </div>
                <div className="column is-5"></div>
              </div>
              <div className="columns has-text-centered">
                <div className="column is-4"></div>
                <div className="column">
                  <p>{post.getStarted.blurb}</p>
                </div>
                <div className="column is-4"></div>
              </div>

              <div className="columns">
                <div className="column is-4"></div>
                <div className="column">
                  <ButtonGroup isCentered>
                    <Button buttonText={post.getStarted.buttonText} href={post.getStarted.href} />
                    <Button buttonText={post.getStarted.buttonTextTwo} href={post.getStarted.hrefTwo} />                    
                  </ButtonGroup>

                </div>
                <div className="column is-4"></div>
              </div>
            </section>

    </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query sandsPage ($loneUrl: String!)  {
  allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
    nodes {
      title
      list
      body
      imageId
      topBlurb
      heading
      metaDescription
      metaTitle
      getStarted {
        heading
        blurb
        buttonText
        buttonTextTwo
        hrefTwo
        href
      }
    }
  }
}
`

export default SandsPage